<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="help">
        <h1>帮助中心</h1>
        <div class="content">
            <ul class="btn-list">
                <li v-for="item in btnList" :key="item.id" :class="{ active: curId === item.id }" @click="tab(item.id)">
                    {{ item.txt }}
                </li>
            </ul>
            <div class="content-r">
                <div class="title">
                    <title-c :textc="activeHelp[0].txt"></title-c>
                </div>
                <p v-if="btnList[0]">
                    <!--bca-disable-->
                    <span class="content-span" v-html="btnList[curId].con"></span>
                    <!--bca-enable-->
                    <span class="bottom-box"> Baidu 京ICP证030173号 </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TitleC from '@/components/publicComponents/TitleC.vue';
import helpList from '@/assets/data/help.js';
export default {
    components: {
        TitleC,
    },
    data() {
        return {
            btnList: [],
            curId: 0,
            activeHelp: [],
        };
    },
    methods: {
        tab(id) {
            this.curId = id;
            this.btnList = helpList;
            this.activeHelp = this.btnList.filter((item) => {
                return item.id === this.curId;
            });
        },
    },
    mounted() {
        let id = this.$route.query.curid;
        if (id) {
            this.curId = id - 1;
        }
        this.btnList = helpList;
        this.activeHelp = this.btnList.filter((item) => {
            return item.id === this.curId;
        });
    },
};
</script>

<style scoped lang='less'>
.help {
    h1 {
        width: 1200px;
        font-weight: bold;
        text-align: left;
        font-size: 20px;
        margin: 15px auto;
    }
    .title {
        margin-left: 15px;
    }
    .content {
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        display: flex;
        justify-content: space-between;
        white-space: pre-line;
        .content-r {
            width: 886px;
            height: 842px;
            background: #fff;
            box-sizing: border-box;
            padding: 15px 32px;
            overflow-y: scroll;
            .content-span {
                display: inline-block;
                min-height: 680px;
            }
            .bottom-box {
                display: block;
                border-top: 1px dotted #e6e6eb;
                color: rgb(188, 190, 192);
                font-size: 12px;
                text-align: center;
                padding-top: 15px;
            }
            p {
                font-size: 16px;
                color: #383d47;
                border-top: 1px dotted #e6e6eb;
                padding-top: 10px;
            }
        }
        .btn-list {
            width: 298px;
            height: 842px;
            background: #fff;
            box-sizing: border-box;
            padding: 10px;
            li {
                height: 48px;
                line-height: 48px;
                text-align: left;
                font-size: 17px;
                color: #383d47;
                border-radius: 4px;
                white-space: nowrap;
                overflow: hidden;
                box-sizing: border-box;
                padding-left: 15px;
                text-overflow: elipsis;
                &.active {
                    background: #f5f7fc;
                    color: #2d63e0;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>