<!--
 * @FilePath: /copyright/src/components/publicComponents/TitleC.vue
 * @Description: 添加文件描述~~~
 * @Author: jiangshengkai@baidu.com
 * @Date: 2022-10-12 15:49:15
 * @LastEditors: jiangshengkai@baidu.com
 * @LastEditTime: 2023-02-07 18:23:31
-->
<template>
    <div class="titleC">
        <i></i>
        <h1>{{ textc }}</h1>
    </div>
</template>

<script>
export default {
    props: {
        textc: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang='less'>
.titleC {
    height: 20px;
    padding: 24px 0;
    position: relative;
    left: -15px;
    i {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url(../../assets/img/title2.png) no-repeat;
        background-size: 100% 100%;
    }
    h1 {
        display: inline-block;
        font-size: 20px;
        color: #1e2024;
        font-weight: 600;
        line-height: 20px;
        text-indent: 5px;
    }
}
</style>
